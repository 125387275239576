<template>
  <div>

    <div class="form">
      <el-form :inline="true" label-width="80px" :model="form">
        <el-row>
          <el-col :span="7">
            <el-form-item label="项目名称" prop="productName">
              <el-input v-model="form.itemName" clearable placeholder="请输入项目名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="产品类别" prop="productCategory">
              <el-select v-model="form.productCategory" clearable placeholder="请选择产品类别">
                <el-option v-for="item in $store.state.category"
                           :key="item.dictKey"
                           :label="item.dictValue"
                           :value="item.dictKey"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item>
              <el-button type="primary" @click="onSubmit(form)">查询</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="list" style="min-height: calc( 100vh - 214px )"  >
      <div class="operation">
        <el-button @click="addClick" size="small" type="primary">添加项目</el-button>
      </div>

      <el-table
          :data="table"
          v-loading="tableLoad"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          style="width: 100%">
        <el-table-column
            prop="itemName"
            align="center"
            label="项目名称">
          <template slot-scope="scope">
            <span class="blue" @click="deta(scope.row)" >{{ scope.row.itemName }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="itemContent"
            align="center"
            :show-overflow-tooltip='true'
            label="项目描述">
        </el-table-column>
        <el-table-column
            align="center"
            :show-overflow-tooltip='true'
            label="具体内容">
          <template slot-scope="scope">
            <span>{{ scope.row.itemDesc }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="productCategory"
            align="center"
            label="产品类别">
          <template slot-scope="scope">
            <span>{{ $store.state.category.filter((item) => { return item.dictKey == scope.row.productCategory })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="fitGender"
            align="center"
            label="适用性别">
          <template slot-scope="scope">
            <span>{{ $store.state.fitGender.filter((item) => { return item.dictKey == scope.row.fitGender })[0].dictValue }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="itemType"
            align="center"
            label="项目类型">
        </el-table-column>
        <el-table-column
            prop="itemPlusType"
            align="center"
            label="附加项类型">
        </el-table-column>
        <el-table-column
            prop="itemPrice"
            align="center"
            label="项目单价">
          <template slot-scope="scope">
            <span>{{ scope.row.itemPrice + "元" }}</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="createTime"
            align="center"
            label="添加时间">
        </el-table-column>
        <el-table-column
            fixed="right"
            align="center"
            label="操作">
          <template slot-scope="scope">
              <el-button type="text" size="small" @click="del(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="form.pageNum"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="form.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>


  </div>
</template>

<script>
export default {
  name: "ProductExa",
  data() {
    return{
      form: {
        pageNum: 1,
        pageSize: 10,
        itemName: null,
        productCategory: null,
        itemType: null,
      },
      table: [],
      total: 0,
      tableLoad: false,
    }
  },
  created() {
    this.get_list(this.form);

  },
  mounted() {

  },
  methods: {
    addClick() {
      this.$router.push({
        path: "/ProductExaDeta"
      })
    },
    deta(e) {

      this.$router.push({
        path: "/ProductExaDeta",
        query: {
          id: e.itemId
        }
      })

    },
    onSubmit(formName) {
      formName.pageNum= 1;
      this.get_list(formName);
    },
    get_list(y) {
      this.$get("product-item",y)
        .then(res => {
          if(res) {
            console.log(res);
            this.table= res.data.list;
            this.total= res.data.total;

          }
        })
    },
    del(e) {
      console.log(e);
      this.$confirm('此操作将删除此产品项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$del("product/item",e.itemId,true)
            .then(res => {
              if(res) {
                if(res) {
                  this.$message({
                    type: 'success',
                    message: '删除成功!'
                  });
                  this.get_list(this.form);
                }
              }
            })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });
      });

    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.form.pageNum= e;
      this.get_list(this.form);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.form.pageNum= 1;
      this.form.pageSize= e;
      this.get_list(this.form);
    },
  }
}
</script>

<style scoped>

</style>
